import React, { useEffect } from 'react';
import { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { MenuItems } from './MenuItems';
import './NavBar.css';
import { useTCP } from "./../provider/TCPProvider";
import { useAuth } from "./../provider/authProvider";
import Waiting from './Waiting';
export default function Navbar() {

  const { environment, setEnvironment } = useTCP();
  const { user } = useAuth();
  const [clickedButton, setClickedButton] = useState("");


  useEffect(()=>{
    var env=localStorage.getItem('environment')
    setClickedButton(env)
    console.log("NAVBAR :"+env)
  })


  const handleEnvironmentChange = (newEnvironment) => {
    setEnvironment(newEnvironment);
    setClickedButton(newEnvironment);
  };

  return (
    <div className="Navbar" >
      <div className="Navbar-logo">
        <div className="Navbar-logotcp-container">
          <Link to="/">
            <img
              src="https://s3.fr-par.scw.cloud/static.tcp/WhiteLogoBorder.png"
              className="logo img-fluid"
              alt="Logo TheCrossProduct"
              width="100"
            />
          </Link>
        </div>
        <div className="Navbar-menu-icon"></div>
        <ul>
          {MenuItems.map((item, index) => {
            return (<>
              {((!item?.scope) || (user?.scope?.includes(item.scope))) && ((!item?.nscope) || !user?.scope?.includes(item.nscope)) &&
                <li key={index}>
                  <NavLink to={item.url} className={item.cName}>{item.Title}</NavLink>
                </li>
              }
            </>
            );
          })}
        </ul>
      </div>
      {(user) && user?.scope?.includes("dev") ?
        <ul>
          <li>
            <button
              className={clickedButton === "stable" ? "clicked" : ""}
              onClick={() => handleEnvironmentChange("stable")}
            >
              Stable </button>
          </li>
          <li>
            <button
              className={clickedButton === "staging" ? "clicked" : ""}
              onClick={() => handleEnvironmentChange("staging")}
            >
              Staging
            </button>
          </li>
          <li>
            <button
              className={clickedButton === "local" ? "clicked" : ""}
              onClick={() => handleEnvironmentChange("local")}
            >
              Local
            </button>
          </li>
        </ul> : ""
      }
    </div >
  )
}