import { createContext, useContext, useEffect, useMemo, useState } from "react";

const TCPContext = createContext();

const TCPProvider = ({ children }) => {

  const [environment, setEnvironment_] = useState(
    localStorage.getItem('environment') !== null ? localStorage.getItem('environment') : 'stable');

  const environments = {
    stable: "https://api.thecrossproduct.xyz",
    staging: "https://staging.api.thecrossproduct.xyz",
    local: "http://localhost:3000"
  };

  const [TCPUrl, setTCPUrl_] = useState(environments[environment]);

  const setTCPUrl = (newurl) => {
    setTCPUrl_(newurl);
    console.log(newurl)
  };

  const setEnvironment = (newenv) => {
    setEnvironment_(newenv)
    console.log(newenv)
    setTCPUrl(environments[newenv])
  };

  const TCPAuth = TCPUrl + "/v1/auth"
  const TCPData = TCPUrl + "/v1/data"
  const TCPApp = TCPUrl + "/v1/app"
  const TCPLics = TCPUrl + "/v1/lics"
  const TCPWS = "https://ws.thecrossproduct.xyz"

  useEffect(() => {
   setEnvironment(localStorage.getItem("environment"));
   console.log(environment)
  }, []);

  useEffect(() => {
    if (environment) {
      localStorage.setItem("environment", environment);
    } else {
      localStorage.removeItem("environment");
    }
  }, [environment]);

  const contextValue = useMemo(
    () => ({
      TCPUrl,
      TCPAuth,
      TCPData,
      TCPApp,
      TCPLics,
      TCPWS,
      setTCPUrl,
      setEnvironment
    }),
    [TCPUrl, environment, setEnvironment]
  );
  return (
    <TCPContext.Provider value={contextValue}>{children}</TCPContext.Provider>
  );
};

export const useTCP = () => {
  return useContext(TCPContext);
};

export default TCPProvider;
